<template>
  <div id="app">
    <NavBar v-if="showNavBar"/>
    <router-view/>
  </div>
</template>
<script>

import NavBar from './components/NavBar.vue';





export default {
  name: 'App',
  components: {
    NavBar
  },
  data() {
    return {
      showNavBar: true,
      // Additional data properties
    };
  },
 
  created() {
  const username = localStorage.getItem('username');
  const userRole = localStorage.getItem('userRole');
  if (username && userRole) {
    // Implement logic to handle automatic login or session restoration
    // For example, update the store or state to reflect the user is logged in
  }
},

  watch: {
    $route(to) {
      this.showNavBar = to.name !== 'Login';
    }
  }
};
</script>
