import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../components/LoginView.vue';
import HomeP from '../components/HomeP.vue';
import HomeK from '../components/HomeK.vue';
import PastK from '../components/PastK.vue';
import PastP from '../components/PastP.vue';
import TlistaP from '../components/TlistaP.vue'; // Import TlistaP component for pomo
import HomeT from '../components/HomeT.vue'; // Import HomeT component for tekija

const routes = [
  { path: '/', name: 'Login', component: LoginView },
  { path: '/pomo', name: 'pomo', component: HomeP, meta: { role: 'pomo' } },
  { path: '/kuljettaja', name: 'kuljettaja', component: HomeK, meta: { role: 'kuljettaja' } },
  { path: '/menneetK', name: 'pastKuljettaja', component: PastK, meta: { role: 'kuljettaja' } },
  { path: '/menneetP', name: 'pastPomo', component: PastP, meta: { role: 'pomo' } },
  { path: '/tlistaP', name: 'tlistaP', component: TlistaP, meta: { role: 'pomo' } }, // New route for TlistaP (pomo)
  { path: '/homeT', name: 'homeT', component: HomeT, meta: { role: 'tekija' } }, // New route for HomeT (tekija)
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Check if the route requires a role
  if (to.meta.role) {
    // Retrieve user role from local storage
    const userRole = localStorage.getItem('userRole');
    
    // Check if user role matches the required role for the route
    if (userRole === to.meta.role) {
      next(); // Proceed if role matches
    } else {
      alert('Access denied: You do not have permission to view this page.');
      next({ name: 'Login' }); // Redirect to login if role doesn't match
    }
  } else {
    next(); // Proceed if no role is required
  }
});

export default router;
