<template>
    <div class="work-list-container">
      <h2 class="work-list-heading">Työlistat</h2>
      <div v-if="userRole === 'pomo' || userRole === 'tekija'">
        <ul class="work-list-list" v-if="lists.length > 0">
          <li v-for="list in lists" :key="list.id" class="work-list-item">
            <div>
              <div class="work-list-item-details">
                <p><strong>Lista:</strong> {{ list.text }}</p>
                <p><strong>Tehty:</strong> {{ formatDate(list.createdAt) }}</p>
                <p><strong>Työntekijä:</strong> {{ list.assignedWorker }}</p>
                <p><strong>Tehnyt:</strong> {{ list.username }}</p>
              </div>
              <div class="work-list-item-info">
                <button v-if="userRole === 'pomo' || (userRole === 'tekija' && list.assignedWorker === userRole)" 
                        @click="deleteList(list.id)" class="delete-button">Poista lista</button>
              </div>
            </div>
          </li>
        </ul>
        <p v-else>Ei työlistoja</p>
        <button @click="fetchAssignedLists" class="update-button">Päivitä</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'HomeT',
    data() {
      return {
        userRole: localStorage.getItem('userRole'),
        lists: [],
      };
    },
    methods: {
      async fetchAssignedLists() {
        try {
          const baseURL = process.env.VUE_APP_API_BASE_URL;
          const response = await axios.get(`${baseURL}/lists`, {
            params: { userRole: this.userRole }
          });
          this.lists = response.data;
        } catch (error) {
          console.error('Error fetching assigned lists:', error);
        }
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString();
      },
      async deleteList(listId) {
        try {
          const baseURL = process.env.VUE_APP_API_BASE_URL;
          await axios.delete(`${baseURL}/lists/${listId}`);
          this.fetchAssignedLists(); // Refresh the lists after deletion
        } catch (error) {
          console.error('Error deleting list:', error);
        }
      }
    },
    mounted() {
      this.fetchAssignedLists();
    }
  };
  </script>
 
 <style scoped>
/* Base styles */
body {
  font-family: Arial, sans-serif;
}

/* Component container */
.work-list-container {
  padding: 20px;
}

/* Form and input styling */
.work-list-form,
.order-form {
  margin-bottom: 20px;
}

/* Individual form group styling */
.work-list-form .form-group,
.order-form .form-group {
  margin-bottom: 20px;
}

/* Label styling */
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Text input, textarea, and select styling */
input[type="text"],
textarea,
select,
.work-list-form input[type="text"],
.work-list-form select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;
}

/* Button styling */
button,
.work-list-form button {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

/* List styling */
.work-list-list,
.order-list {
  padding: 0;
  list-style: none;
}

/* Individual list item styling */
.work-list-item,
.order-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive design adjustments */
@media (min-width: 768px) {
  .work-list-container,
  .home {
    max-width: 800px;
    margin: 0 auto;
  }

  .work-list-form,
  .order-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-group:last-child,
  .work-list-form .form-group:last-child {
    grid-column: span 2;
  }
}
</style>
