<template>
    <div class="home">
      <div v-if="unseenOrders.length > 0">
        <h2>Kuittaamattomat tilaukset</h2>
        <ul>
          <li v-for="order in unseenOrders" :key="order._id">
            <p><strong>Tilausaika:</strong> {{ new Date(order.createdAt).toLocaleString() }}</p>
            <p><strong>Tilaaja:</strong> {{ order.username }}</p>
           <p><strong>Nouto-osoite:</strong> {{ order.pickupAddress }}</p>
          <p><strong>Vientiosoite:</strong> {{ order.deliveryAddress }}</p>
          <p><strong>Tavaroiden tiedot:</strong> {{ order.itemsDetails }}</p>
          <p><strong>Kommentit:</strong> {{ order.comments }}</p>
          <p><strong>Kiireellisyysluokka:</strong> {{ order.urgencyLevel }}</p>
          <p><strong>Status:</strong> 
            <select v-model="order.status" @change="updateOrderStatus(order)">
              <option value="ei_nähty">Ei nähty</option>
              <option value="nähty">Nähty</option>
              <option value="toimitettu">Toimitettu</option>
            </select>
          </p>
        </li>
      </ul>
    </div>
      
     
      <h2>Tilaukset</h2>
      <h3>Kiireellisyysluokat: 1= Heti, 2= Tänään, 3= Huomisaamuksi, 4= Huomenna</h3>
      <ul>
        <li v-for="order in seenOrders" :key="order._id">
            <p><strong>Tilausaika:</strong> {{ new Date(order.createdAt).toLocaleString() }}</p>
         <p><strong>Tilaaja:</strong> {{ order.username }}</p>
          <p><strong>Nouto-osoite:</strong> {{ order.pickupAddress }}</p>
          <p><strong>Vientiosoite:</strong> {{ order.deliveryAddress }}</p>
          <p><strong>Tavaroiden tiedot:</strong> {{ order.itemsDetails }}</p>
          <p><strong>Kommentit:</strong> {{ order.comments }}</p>
          <p><strong>Kiireellisyysluokka:</strong> {{ order.urgencyLevel }}</p>
          <div v-if="order.photos && order.photos.length">
          <h3>Kuvat</h3>
          <div class="photo-thumbnails">
            <img
              v-for="(photo, idx) in order.photos"
              :key="`photo-${idx}`"
              :src="photo"
              :alt="`Order photo ${idx+1}`"
              class="order-photo"
              @click="showPhoto(photo)"
            />
          </div>
        </div>
          <p><strong>Status:</strong> 
            <select v-model="order.status" @change="updateOrderStatus(order)">
              <option value="ei_nähty">Ei nähty</option>
              <option value="nähty">Nähty</option>
              <option value="toimitettu">Toimitettu</option>
            </select>
          </p>
       
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'HomeK',
    data() {
      return {
        orders: []
      };
    },
    computed: {
      unseenOrders() {
        return this.orders.filter(order => order.status === 'ei_nähty');
      },
      seenOrders() {
        return this.orders.filter(order => order.status !== 'toimitettu' && order.status !== 'ei_nähty');
      }
    },
    mounted() {
      this.fetchOrders();
    },
    methods: {
        async fetchOrders() {
  try {
    const baseURL = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${baseURL}/orders`);
    // Set the default status for each order if not already specified
    this.orders = response.data.map(order => ({
      ...order,
      status: order.status || 'ei_nähty' // Default to 'ei_nähty' if status is falsy
    }));
  } catch (error) {
    console.error('Virhe tilausten hakemisessa:', error);
    // Optionally, add UI feedback for the user here
  }
},

      async updateOrderStatus(order) {
        try {
          const baseURL = process.env.VUE_APP_API_BASE_URL;
        const newStatus = order.status;
        await axios.put(`${baseURL}/orders/${order._id}`, { status: newStatus });
          if (newStatus === 'toimitettu') {
            this.orders = this.orders.filter(o => o._id !== order._id);
          }
        } catch (error) {
          console.error('Virhe tilauksen statuksen päivittämisessä:', error);
          // Consider adding UI feedback for the user here
        }
      }
    }
  };
  </script>
  
  
  
  <style scoped>
  /* Modern styling */
  .home {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .order-form {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  textarea,
  select {
    width: 100%;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 16px;
    margin-top: 5px;
    box-sizing: border-box;
  }
  
  button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .order-list {
    padding: 0;
    list-style: none;
  }
  
  .order-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: 768px) {
    .home {
      max-width: 800px;
      margin: 0 auto;
    }
  
    .order-form {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  
    .form-group {
      margin-bottom: 0;
    }
  
    .form-group:last-child {
      grid-column: span 2;
    }
  }
  </style>
  