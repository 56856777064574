<template>
  <nav>
    <router-link to="/pomo" v-if="userRole === 'pomo'">Uudet tilaukset</router-link>
    <router-link to="/menneetP" v-if="userRole === 'pomo'">Menneet tilaukset</router-link>
    <router-link to="/kuljettaja" v-if="userRole === 'kuljettaja'">Uudet tilaukset</router-link>
    <router-link to="/menneetK" v-if="userRole === 'kuljettaja'">Menneet tilaukset</router-link>
   <router-link to="/tlistaP" v-if="userRole === 'pomo'">Työlistat</router-link>  
    <!-- Logout Button -->
    <button @click="logout">Kirjaudu ulos</button>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      userRole: localStorage.getItem('userRole'), // Retrieve the user role
    };
  },
  methods: {
    logout() {
      // Clear session data from localStorage
      localStorage.removeItem('username');
      localStorage.removeItem('userRole');
      localStorage.removeItem('stayLoggedIn');
      // Redirect to login page or perform other cleanup
      this.$router.push({ name: 'Login' });
    }
  },
};
</script>


  <style>
  /* Add your navbar styles here */
  nav {
    /* Example style */
    display: flex;
    justify-content: space-around;
    background-color: #333;
    padding: 1rem;
  }
  nav a {
    color: white;
    text-decoration: none;
  }
  </style>
  