<template>
    <div class="home">
      <h2>Luo uusi tilaus</h2>
      <form @submit.prevent="submitOrder">
        <div>
          <label for="pickupAddress">Nouto-osoite:</label>
          <input id="pickupAddress" v-model="order.pickupAddress" type="text" required>
        </div>
        <div>
          <label for="deliveryAddress">Vientiosoite:</label>
          <input id="deliveryAddress" v-model="order.deliveryAddress" type="text" required>
        </div>
        <div>
          <label for="itemsDetails">Tavaroiden tiedot:</label>
          <textarea id="itemsDetails" v-model="order.itemsDetails" required></textarea>
        </div>
        <!-- Kuvan lataus toiminnallisuus voidaan lisätä tässä -->
        <div>
          <label for="comments">Kommentit:</label>
          <textarea id="comments" v-model="order.comments"></textarea>
        </div>
        <div>
          <label for="urgencyLevel">Kiireellisyysluokka:</label>
          <select id="urgencyLevel" v-model="order.urgencyLevel" required>
            <option value="" disabled>Valitse</option>
            <option value="1">Heti</option>
            <option value="2">Tänään</option>
            <option value="3">Huomisaamuksi</option>
            <option value="4">Huomenna</option>
          </select>
        </div>
       <!--<div>
        <label for="photos">Kuvat:</label>
        <input id="photos" type="file" multiple @change="handleFileUpload">
      </div> --> 

        <button type="submit">Lähetä tilaus</button>
      </form>
   
    <h2>Tilaukset</h2>
    <ul>
      <li v-for="(order, index) in orders" :key="index">
        <p><strong>Nouto-osoite:</strong> {{ order.pickupAddress }}</p>
        <p><strong>Vientiosoite:</strong> {{ order.deliveryAddress }}</p>
        <p><strong>Tavaroiden tiedot:</strong> {{ order.itemsDetails }}</p>
        <p><strong>Kommentit:</strong> {{ order.comments }}</p>
        <p><strong>Kiireellisyysluokka:</strong> {{ order.urgencyLevel }}</p>
        <p><strong>Status:</strong> {{ order.status }}</p>
        <p><strong>Tilaaja:</strong> {{ order.username }}</p>
        <p><strong>Tilausaika:</strong> {{ new Date(order.createdAt).toLocaleString() }}</p>
        <div v-if="order.photos && order.photos.length">
          <h3>Kuvat</h3>
          <div class="photo-thumbnails">
            <img
              v-for="(photo, idx) in order.photos"
              :key="`photo-${idx}`"
              :src="photo"
              :alt="`Order photo ${idx+1}`"
              class="order-photo"
              @click="showPhoto(photo)"
            />
          </div>
        </div>
        <div>
          <button @click="editOrder(order)">Muokkaa</button>
          <button @click="deleteOrder(order._id)">Poista</button>
        </div>
      </li>
    </ul>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import { v4 as uuidv4 } from 'uuid';
  import AWS from 'aws-sdk';

// Configure AWS SDK
AWS.config.update({
  accessKeyId: 'AKIAYS2NVK2ZVF6MJUAF',
  secretAccessKey: '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',
  region: 'eu-north-1',
});

// Create S3 object
const s3 = new AWS.S3();

  
  export default {
    name: 'HomeP',
    data() {
      return {
        order: {
          pickupAddress: '',
          deliveryAddress: '',
          itemsDetails: '',
          comments: '',
          urgencyLevel: '',
          photos: [],
          _id: null, // Add this line to track the order being edited
        },
        orders: [],
        orderFiles: [],
        currentPhoto: null, // For modal photo viewing
        editing: false, // Track if we are editing an order
      };
    },
    async mounted() {
      await this.fetchOrders();
    },
    methods: {
      async fetchOrders() {
        try {
          const baseURL = process.env.VUE_APP_API_BASE_URL;
          const response = await axios.get(`${baseURL}/orders`);
          this.orders = response.data.filter(order => order.status !== 'toimitettu');
        } catch (error) {
          console.error('Error fetching orders:', error);
        }
      },
      handleFileUpload(event) {
        this.orderFiles = event.target.files;
      },
      async submitOrder() {
  try {
    const formData = new FormData();
    // Append order details to formData
    Object.keys(this.order).forEach(key => {
      if (key !== '_id' && key !== 'photos') {
        formData.append(key, this.order[key]);
      }
    });

    // Upload photos to S3
    const photoUrls = await this.uploadPhotosToS3(this.orderFiles);
    photoUrls.forEach(url => formData.append('photos', url));

    const username = localStorage.getItem('username');
    formData.append('username', username);

    // Submit order to your API
    const baseURL = process.env.VUE_APP_API_BASE_URL;
    if (this.editing && this.order._id) {
      await this.updateOrder(formData);
    } else {
      const response = await axios.post(`${baseURL}/orders`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Order submitted:', response.data);
    }
    this.resetForm();
    await this.fetchOrders();
  } catch (error) {
    console.error('Error submitting order:', error);
  }
}

,

async uploadPhotosToS3(photos) {
 



const bucketName = 'mkf22';
  const photoUrls = [];
  for (const photo of photos) {
    const fileName = `${uuidv4()}-${photo.name}`;
    const photoUrl = `https://${bucketName}.s3.amazonaws.com/${fileName}`;
    await s3.upload({
      Bucket: bucketName,
      Key: fileName,
      Body: photo,
      ACL: 'public-read', // Set ACL as needed
    }).promise();
    photoUrls.push(photoUrl);
  }
  return photoUrls;
}
,
      resetForm() {
        this.order = {
          pickupAddress: '',
          deliveryAddress: '',
          itemsDetails: '',
          comments: '',
          urgencyLevel: '',
          _id: null,
        };
        this.orderFiles = [];
        this.editing = false; // Reset editing flag
      },
      editOrder(order) {
        this.order = { ...order };
        this.editing = true;
      },
      async updateOrder(formData) {
  if (!this.order._id) {
    console.error('Order ID is missing');
    return;
  }
  
  const confirmed = confirm('Haluatko varmasti päivittää tämän tilauksen?');
  if (!confirmed) return;

  const baseURL = process.env.VUE_APP_API_BASE_URL;
  try {
    await axios.put(`${baseURL}/orders/${this.order._id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Tilaus päivitetty');
    this.resetForm();
    await this.fetchOrders();
  } catch (error) {
    console.error('Error updating order:', error);
  }
}
,
      async deleteOrder(orderId) {
        const confirmed = confirm('Halautko varmasti poistaa?');
        if (!confirmed) return;
  
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        try {
          await axios.delete(`${baseURL}/orders/${orderId}`);
          console.log('Tilaus poistettu');
          await this.fetchOrders();
        } catch (error) {
          console.error('Error deleting order:', error);
        }
      },
      
      showPhoto(photoUrl) {
        this.currentPhoto = photoUrl;
      },
      formatOrderDate(dateString) {
        if (!dateString) return 'Unknown';
        const date = new Date(dateString);
        return date.toLocaleString();
      }
    }
  };
  </script>
  
  



  
<style scoped>
/* Modern styling */

.order-photo {
  width: 100px;
  height: auto;
  cursor: pointer;
  margin-right: 5px;
}
.home {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.order-form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
textarea,
select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;
}

button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.order-list {
  padding: 0;
  list-style: none;
}

.order-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.photo-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.thumbnail {
  margin-right: 10px; /* Space out thumbnails */
}


@media (min-width: 768px) {
  .home {
    max-width: 800px;
    margin: 0 auto;
  }

  .order-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-group:last-child {
    grid-column: span 2;
  }
}
</style>
