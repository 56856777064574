<template>
  <div>
    <h2>Menneet tilaukset</h2>
    <ul>
      <li v-for="order in pastOrders" :key="order._id">
        <p><strong>Nouto-osoite:</strong> {{ order.pickupAddress }}</p>
        <p><strong>Vientiosoite:</strong> {{ order.deliveryAddress }}</p>
        <p><strong>Tavaroiden tiedot:</strong> {{ order.itemsDetails }}</p>
        <p><strong>Kommentit:</strong> {{ order.comments }}</p>
        <p><strong>Kiireellisyysluokka:</strong> {{ order.urgencyLevel }}</p>
        <p><strong>Kuittaus:</strong> {{ order.status }}</p>
        <p><strong>Tilaaja:</strong> {{ order.username }}</p>
        <p><strong>Tilausaika:</strong> {{ new Date(order.createdAt).toLocaleString() }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PastK',
  data() {
    return {
      pastOrders: [],
    };
  },
  async mounted() {
    await this.fetchPastOrders();
  },
  methods: {
    async fetchPastOrders() {
      try {
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${baseURL}/orders?status=toimitettu`);
        this.pastOrders = response.data;
      } catch (error) {
        console.error('Error fetching past orders:', error);
      }
    },
  },
};
</script>

<style scoped>
h2 {
  color: #333;
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  transition: background-color 0.3s;
}

li:hover {
  background-color: #eaeaea;
}

p {
  margin: 5px 0;
}

strong {
  color: #333;
}
</style>
