<template>
    <div>
      <h2>Työlistat</h2>
      <div v-if="userRole === 'pomo'">
        <textarea v-model="newList" placeholder="Kirjoita uusi lista"></textarea>
        <select v-model="selectedWorker">
  <!-- Placeholder option -->
  <option disabled value="" selected>Valitse työntekijä</option>

  <!-- Iterating through workers to display options -->
  <option v-for="(role, worker) in workers" :key="worker" :value="worker">
    {{ worker }}
  </option>
</select>


        <button @click="submitLista">Lähetä</button>
      </div>
      <div v-else>
        <p>Uuden listan lähettäminen ei onnistunut.</p>
      </div>
      
      <!-- Display lists -->
      <div v-if="userRole === 'pomo' || userRole === 'tekija'">
  <h2>Listat</h2>
  <ul v-if="lists.length > 0">
    <li v-for="list in lists" :key="list.id">
      <div>
        <p><strong>Lista:</strong> {{ list.text }}</p>
        <p><strong>Tehty:</strong> {{ formatDate(list.createdAt) }}</p>
     
        <p> <strong>Työntekijä:</strong> {{ list.assignedWorker }}</p>
        <p> <strong>Tehnyt</strong> {{ list.username }}</p>
        <button v-if="userRole === 'pomo' || (userRole === 'tekija' && list.assignedWorker === userRole)" @click="deleteList(list.id)">Poista lista</button>
      </div>
    </li>
  </ul>
  <p v-else>Ei työlistoja</p>
  <button @click="fetchAssignedLists">Päivitä</button>
</div>

    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'TlistaP',
    data() {
      return {
        userRole: localStorage.getItem('userRole'),
        newList: '',
        selectedWorker: '',
        lists: [],
        workers: {
          'Pete.L': 'pomo',
          'Lemmo.R': 'pomo',
          'Tuomo.T': 'tekija'
        }
      };
    },
    methods: {
      async submitLista() {
        try {
          const baseURL = process.env.VUE_APP_API_BASE_URL;
          const username = localStorage.getItem('username'); // Assuming username is needed
          const response = await axios.post(`${baseURL}/lists`, {
            text: this.newList,
            assignedWorker: this.selectedWorker,
            username // Include username if required by server
          });
          console.log('New list submitted:', response.data);
          this.fetchAssignedLists(); // Refresh lists
          alert('List successfully submitted.'); // User feedback
        } catch (error) {
          console.error('Error submitting list:', error);
          alert('Failed to submit list.'); // User feedback on error
        }
      },
      async fetchAssignedLists() {
        try {
          const baseURL = process.env.VUE_APP_API_BASE_URL;
          const response = await axios.get(`${baseURL}/lists`); // Add any required query parameters
          this.lists = response.data;
        } catch (error) {
          console.error('Error fetching assigned lists:', error);
        }
      },
      async deleteList(listId) {
        try {
          const baseURL = process.env.VUE_APP_API_BASE_URL;
          await axios.delete(`${baseURL}/lists/${listId}`);
          this.fetchAssignedLists(); // Refresh lists after deletion
          alert('List successfully deleted.'); // User feedback
        } catch (error) {
          console.error('Error deleting list:', error);
          alert('Failed to delete list.'); // User feedback on error
        }
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString();
      }
    },
    mounted() {
      this.fetchAssignedLists();
    }
  };
  </script>
  
  
  <style scoped>
/* Aligning styles with HomeP */

/* Base styles */
body {
  font-family: Arial, sans-serif;
}

.work-list-container {
  padding: 20px;
}

.work-list-form,
.order-form {
  margin-bottom: 20px;
}

.work-list-form .form-group,
.order-form .form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
textarea,
select,
.work-list-form input[type="text"],
.work-list-form select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;
}

button,
.work-list-form button {
  width: 100%;
  padding: 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.work-list-list,
.order-list {
  padding: 0;
  list-style: none;
}

.work-list-item,
.order-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (min-width: 768px) {
  .work-list-container,
  .home {
    max-width: 800px;
    margin: 0 auto;
  }

  .work-list-form,
  .order-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-group:last-child,
  .work-list-form .form-group:last-child {
    grid-column: span 2;
  }
}
</style>
